
<template>
          <div class="mx-auto" style="max-width: 1000px; position: relative">
            <Card class="card-custom card-block">
              <template #title>
                <div class="column-wrapper align-center">
                  <div class="column-three text-left"><back-button /></div>
                    <h4 class="c-black flex-1 column-three">{{$t('bonus_data')}}</h4>
                    <div class="column-three"></div>
                </div>
              </template>
              <template #content>
                <ValidationObserver ref="observer" v-slot="{ invalid, validate }">

                        <!-- multiple -->
                        <Card class="card-custom form-block">
                          <template #title>
                            <h4>
                              {{ $t('min_dep') }} - {{ $t('max_dep') }}
                            </h4>
                            </template>
                          <template #content>
                            <div class="space-y-1">
                          <!-- :class="{ 'mb-3' : selectedCurrencies.length > 3 }" -->
                            <ValidationProvider
                        :name="$t('currency')"
                        rules="required"
                        v-slot="{ errors, validate, validated }"
                      > 
                        <h4 class="c-text white-space title-input">{{ $t('currency') }}<span class="c-red">*</span>:</h4>
                        <div class="wrapper-input d-flex flex-column">
                          <MultiSelect
                          :disabled="isAllCurrencies"
                        v-model="selectedCurrencies"
                        :options="currencies"
                        class="custom-dropdown fw"
                        style="flex: 1 1 auto"
                        :class="{ 'p-invalid' : errors.length > 0 }"
                        :placeholder="$t('currency')"
                        :filter="true"
                        :emptyFilterMessage="$t('no_data')"
                      >
                      </MultiSelect>
                          <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                    <ValidationObserver v-slot="{ invalid, errors }" v-for="(limits, currency) in depBonus.currency_limits" :key="currency">
                              <h4 class="c-text white-space title-input">
                                {{ currency }}:
                              </h4>
                              <div class="d-flex flex-column fw">
                                <div class="d-flex md-flex-column gap-1 fw">
                                  <ValidationProvider
                                  tag="div"
                                  class="d-flex flex-column fw"
                                    name="min_dep"
                                    :rules="{ required: true, min_less_than_max: { min: depBonus.currency_limits[currency].min_dep, max: depBonus.currency_limits[currency].max_dep } }"
                                    v-slot="{ errors }"
                                  >
                                  <!-- :suffix="`${$t('min_dep')}: `" -->
                                  <InputNumber 
                                  locale="en-US"
                                  class="flex-auto"
                                  mode="currency"
                                        :currency="currency"
                                        currencyDisplay="code"
                                        :placeholder="$t('amount')"
                                        v-if="selectedCurrencies.includes(currency)"
                                        v-model="depBonus.currency_limits[currency].min_dep"
                                        :step="1"
                                        :min="0"
                                      />
                                      <!-- <span>{{ errors[0] }}</span> -->
                                  </ValidationProvider>
                                  
                                  <ValidationProvider
                                  tag="div"
                                  class="d-flex fw"
                                    :name="$t('max_dep')"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                  <!-- :prefix="`${$t('max_dep')}: `" -->
                                      <InputNumber 
                                        locale="en-US"
                                        class="flex-auto"
                                        mode="currency"
                                        :currency="currency"
                                        currencyDisplay="code"
                                        :placeholder="$t('amount')"
                                        v-if="selectedCurrencies.includes(currency)"
                                        v-model="depBonus.currency_limits[currency].max_dep"
                                        :step="1"
                                        :min="1"
                                      />
                                      <!-- <span>{{ errors[0] }}</span> -->
                                  </ValidationProvider>
                                </div>
                                <span class="p-error font-weight-bold" v-if="errors && errors.min_dep">{{ errors.min_dep[0] }}</span>
                              </div>
                          </ValidationObserver>
                          <Divider />
                        <ValidationProvider
                    :name="$t('bonus_percent')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('bonus_percent') }}<span class="c-red">*</span>:</h4>
                    <div class="wrapper-input">
                        <InputNumber 
                    class="fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('bonus_percent')" 
                    v-model="depBonus.bonus_percent" 
                    showButtons  
                    suffix="%"
                    :step="1"
                    :min="0"
                    :max="100"
                    />
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                        <ValidationProvider
                    :name="$t('wager')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('wager') }}<span class="c-red">*</span>:</h4>
                    <div class="wrapper-input">
                        <InputNumber 
                    class="fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('wager')" 
                    v-model="depBonus.wager" 
                    showButtons  
                    prefix="x"
                    :step="1"
                    :min="0"
                    :max="100"
                    />
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                        <ValidationProvider
                    :name="$t('expire_in_days')"
                    rules="required|min:1"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('expire_in_days') }}<span class="c-red">*</span>:</h4>
                    <div class="wrapper-input">
                        <InputNumber 
                    class="fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('expire_in_days')" 
                    v-model="depBonus.expire_in_days" 
                    showButtons  
                    :step="1"
                    :min="1"
                    />
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                        <ValidationProvider
                    :name="$t('bonus_type')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('bonus_type') }}<span class="c-red">*</span>:</h4>
                    <div class="wrapper-input d-flex flex-column">
                      <Dropdown
                      optionLabel="text"
                      optionValue="value"
                    v-model="depBonus.type"
                    :options="types"
                    class="custom-dropdown fw"
                    style="flex: 1 1 auto"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('bonus_type')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                  </Dropdown>
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                        <ValidationProvider
                    :name="$t('type')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('type') }}<span class="c-red">*</span>:</h4>
                    <div class="wrapper-input d-flex flex-column">
                      <Dropdown
                      optionLabel="text"
                      optionValue="value"
                    v-model="depBonus.sub_type"
                    :options="subTypes"
                    class="custom-dropdown fw"
                    style="flex: 1 1 auto"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('type')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                  </Dropdown>
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                        <ValidationProvider
                    :name="$t('deposit_source')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('deposit_source') }}<span class="c-red">*</span>:</h4>
                    <div class="wrapper-input d-flex flex-column">
                      <Dropdown
                      optionLabel="name"
                      dataKey="id"
                      optionValue="name"
                    v-model="depBonus.deposit_source"
                    :options="getFilteredPayments"
                    class="custom-dropdown fw"
                    style="flex: 1 1 auto"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('deposit_source')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                  </Dropdown>
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
              <span> 
                <h4 class="c-text white-space title-input">
                  {{ $t('active') }}: 
                </h4>
                <div class="d-flex flex-column">
                  <InputSwitch class="p-inputswitch-success" v-model="depBonus.is_enabled" />
                </div>
              </span>
            </div>
            </template>
            </Card>
                    <Button @click="handleSubmit(validate)" 
                :disabled="formLoading" 
                :loading="formLoading"
                :label="$t('create')"
                        icon="pi pi-save" class="p-button-sm p-button-success fw mt-2" />
                </ValidationObserver>
                    
              </template>
            </Card>
          </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
    name: 'CreateDepositBonus',
    computed: {
        ...mapGetters({
            payments: 'getPaymentWays',
            providers: 'providers/getFilteredProviders',
            currencies: 'getFilteredCurrencies',
        }),
        getFilteredPayments() {
            return this.payments.filter(payment => payment.type === 'DEPOSIT');
        }
    },
    watch: {
      isAllCurrencies(newValue) {
    if (newValue) {
      // add currencies
        const currencies = Object.keys(this.currencies).map(currency => {
        return this.currencies[currency]; 
      });
      this.selectedCurrencies = currencies;
      this.selectedCurrenciesForEdit = currencies;
    } else {
      // to delete from selectedCurrencies
      this.selectedCurrencies = [];
      this.selectedCurrenciesForEdit = [];
    }
  },
    selectedCurrencies(newCurrencies) {
      if (!this.depBonus.currency_limits) {
      this.$set(this.depBonus, 'currency_limits', {});
    }
      for (const currency in this.depBonus.currency_limits) {
    if (!newCurrencies.includes(currency)) {
      // delete from selectedCurrencies and currency_limits
      delete this.depBonus.currency_limits[currency];
    }
  }
      newCurrencies.forEach(currency => {
        if (!this.depBonus.currency_limits[currency]) {
          this.$set(this.depBonus.currency_limits, currency, { min_dep: 0, max_dep: 1 });
        }
      });
    },
  },
    data() {
        return {
            
       isAllCurrencies: false,
        loading: false,
        formLoading: false,
        selectedCurrencies: [
        ],
        subTypes: [
          { text: 'Non Sticky', value: 'NON_STICKY' },
            { text: 'Sticky', value: 'STICKY' }
        ],
        types: [
          {
            text: this.$t('welcome'),
            value: 'welcome' ,
          },
          {
            text: this.$t('next_deposit'),
            value: 'next_deposit',
          },
          {
            text: this.$t('no_deposit'),
            value: 'no_deposit',
          },
        ],
        depBonus: {
          currency_limits:{
          },
          bonus_percent: 0,
          sub_type: 'STICKY',
          wager: 0,
          type: 'welcome',
          deposit_source: 'Test',
          is_enabled: true,
          expire_in_days: 1,
        },
        }
    },
    async mounted() {
        if (this.payments.length === 0 ) {
        await this.$store.dispatch('getPaymentWays');
      }
    },
    methods: {
        selectProvider() {
            let providerValue;
            if (!this.provider) {
                providerValue = this.editBonus.provider_name;
            } else {

                providerValue = this.provider;
            }

            if (providerValue) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    },
                };

                const params = {
                    provider: providerValue,
                    size: 1000,
                };

                axios
                    .get('/main-api/games/filter', { params, headers: config.headers })
                    .then(response => {
                        const items = response.data.items;
                        const games = items.map(item => item.Game);
                        this.games = games;
                    })
                    .catch(error => {
                        this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
            detail: error,
            life: 4000 });
                    });
            }
        },
        handleSubmit(validate) {
            validate().then(valid => {
                if (valid) {
                    this.createDepBonus();
                }
            })
        },
      async createDepBonus() {
          this.formLoading = true;
        try {
            await this.$store.dispatch('bonuses/createDepositBonus', this.depBonus);
            this.selectedCurrencies = [];
            await this.$router.push('/deposit-bonuses');
            this.depBonus = {
              currency_limits:{
              },
              bonus_percent: 0,
              currency: 'USD',
              sub_type: 'STICKY',
              wager: 0,
              type: '',
              deposit_source: '',
              is_enabled: true,
              expire_in_days: 1,
            },
            this.$toast.add({ severity: 'success', summary: this.$t('bonus_created'), life: 4000 });
              await this.dispatchDepositBonuses();
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
            detail: error,
            life: 4000 });
          } finally{
            this.formLoading = false;
          }
      },
        async dispatchDepositBonuses() {
            await this.$store.dispatch('bonuses/awaitGetDepositBonuses');
        },
    },
}
</script>

<style lang="scss" scoped>
.form-block{
  //max-height: 214px;
  //overflow: auto;
  display: flex;
  flex-direction: column;
  span{
    display: flex;
    align-items: center;
  }
  .title-input {
    flex: 0 0 200px;
    display: flex;
    //justify-content: flex-end;
    @media (max-width:500px){
      flex: 0 0 100px;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  }
  .wrapper-input {
    //flex: 0 0 200px;
   // width: 100%;
  flex: 1 1 auto;
  @media (max-width:500px){
    flex: 1 1 100%;
  }
  }
  
  .v-input{
    min-width: 170px;
    max-width: max-content;
      margin-right: 5px;
      //&:not(:last-child) {
      //}
      margin-bottom: 6px;
      &.error--text{
          margin-bottom: 0;
      }
  }
  &_height {
      flex-direction: column;
      align-items: stretch;
  }

  &__header {
  }

  &__inputs {
      display: flex;
      flex-wrap: wrap;
      .v-input{
          @media (minh:500px){
              max-width: 170px;
              
          }
      }
  }
}
.half {
  display: flex;
  @media (max-width:850px){
    flex: 1 1 100%;
  }    
  @media (min-width:850px){
    flex-direction: column;
      
  }
  .v-input{
    @media (max-width:850px){
      margin-right: 3px;
      max-width: 50%;
      min-width: 50%;
  }

  }
}
</style>